import {
    ReloadOutlined,
    LoadingOutlined
} from '@ant-design/icons';
import {
    Button,
    message,
    Form,
    Cascader,
    Space
} from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadData, loadDataByDistrict, generateId } from '../Actions/Main-Actions';
import { LOCAL_LOCATION, LOGIN_VIEW } from '../Consts/Consts';
import TableView from './TableView';
import { isValidAdditional } from '../Utils/Utils';
import { DistrictsData } from '../Consts/DataConstants';

const CardView = () => {

    const [sheetData, setSheetData] = useState();
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const [districtCode, setDistrictCode] = useState("");
    const options = DistrictsData;

    const handleDistrictSubSectionClick = (e, _label, option) => {
        e.stopPropagation();
        setDistrictCode(option.code);
    };
    const handleDistrictRender = (labels, selectedOptions) =>
        labels.map((label, i) => {
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return (
                    <span key={i} onChange={setDistrictCode(option.code)}>
                        {label} (<a onClick={(e) => handleDistrictSubSectionClick(e, label, option)}>{option.code}</a>)
                    </span>
                );
            }
            return <span key={i}>{label} - </span>;
        });


    const onFinish = async () => {
        setLoading(true)
        let data = {}
        try {
            data = await loadDataByDistrict("DB", districtCode);
            setSheetData(data);
        } catch (error) {
            console.error(error);
            window.localStorage.removeItem(LOCAL_LOCATION);
            navigate(LOGIN_VIEW)
        }
        setLoading(false)
    }
    const navigate = useNavigate();
    const loadTableData = async () => {
        setLoading(true)
        let data = {}
        try {
            data = await loadData("DB");
            setSheetData(data);
        } catch (error) {
            console.error(error);
            window.localStorage.removeItem(LOCAL_LOCATION);
            navigate(LOGIN_VIEW)
        }
        setLoading(false)
    }
    const exportData = (data, name) => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(data)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = name + ".json";

        link.click();
    }
    const downloadData = async () => {
        const data = await loadData("DB");
        exportData(data, "RecordData")
    }
    const downloadPics = async () => {
        const data = await loadData("PIC");
        exportData(data, "PicData")
    }
    const generateIDs = async () => {
        setLoading(true)
        const data = await generateId();
        if (data && data['error'].length === 0) {
            message.info("Generated ID's for the cards !!")
            loadTableData()
            setLoading(false)
        }
        else if (data) {
            message.error("Error in generating the ID's")
            setLoading(false)
        }

    }
    const displayAdditionButtons = () => {
        if (isValidAdditional()) {
            return <>
                <Button type="primary" shape="round" icon={<ReloadOutlined />} onClick={loadTableData}>Load All</Button>
                <Button type="primary" shape="round" icon={<ReloadOutlined />} onClick={downloadData}>Download Records</Button>
                <Button type="primary" shape="round" icon={<ReloadOutlined />} onClick={downloadPics}>Download Pics</Button>
                <Button type="primary" shape="round" icon={<ReloadOutlined />} onClick={generateIDs} loading={loading} disabled={loading}>Generate ID's</Button>
            </>
        }

    }

    return (
        <>
            <div>
                <Form
                    form={form}
                    labelCol={{
                        span: 2,
                    }}
                    wrapperCol={{
                        span: 14,
                    }}
                    layout="horizontal"
                    onFinish={onFinish}
                // onReset={onReset}
                >

                    <Form.Item label="District" name='district' rules={[
                        {
                            required: true,
                            message: 'Invalid data - Please select District'
                        },
                    ]}>
                        <Cascader options={options}
                            displayRender={handleDistrictRender}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type="primary" shape="round" htmlType="submit" loading={loading} icon={<ReloadOutlined />} >
                                Load Data
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>


            </div>
            <Space>



                {displayAdditionButtons()}
            </Space>
            {loading && <div><LoadingOutlined style={{ fontSize: '16px', color: '#08c' }} /></div>}
            {sheetData && <TableView data={sheetData} />}
        </>
    )
}
export default CardView;