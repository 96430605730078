import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Row,
    Space
} from 'antd';

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../config/config';
import '../CSS/login.css'
import { CARD_VIEW, LOCAL_LOCATION } from '../Consts/Consts';
import { emailList } from '../Utils/Utils';
import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';


export const LoginView = () => {
    let navigate = useNavigate();
    const navigateToView = () => {
        navigate(CARD_VIEW)
    }
    const [form] = Form.useForm();
    const loginWithEmail = (email, password) => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                window.localStorage.setItem(LOCAL_LOCATION, userCredential.user.accessToken);
                navigateToView();
            })
            .catch((error) => {
                const errorMessage = error.message;
                message.error("Unable to Login " + errorMessage)
                window.localStorage.removeItem(LOCAL_LOCATION);
            });
    };

    const validateLogin = (email) => {
        return emailList.includes(email);
    }
    const performAction = () => {
        const formFields = form.getFieldsValue();
        const email = formFields.email;
        const password = formFields.password;
        const captcha = formFields.captcha;
        if (!validateLogin(email)) {
            return;
        }
        if (!validateCaptcha(captcha)) {
            message.error("Invalid Captcha")
            return;
        }
        loginWithEmail(email, password)
    }

    useEffect(() => {
        // navigate("/maintenance")
        loadCaptchaEnginge(8)
    }, [])
    return (

        <>
            <section className="hero-bg">
                <div id="hero">
                    <div className="hero-container">
                        <div className="wow fadeIn">


                            <div className="site-card-wrapper">
                                <Row type="flex" justify='center'>
                                    <Col xs={24} sm={20} md={16} lg={16} xl={10} >
                                        <Card title="Login" bordered={false}>
                                            <Form
                                                form={form}
                                                labelCol={{
                                                    span: 4,
                                                }}
                                                wrapperCol={{
                                                    span: 14,
                                                }}
                                                layout="horizontal"
                                                onFinish={performAction}
                                            >

                                                <Form.Item label="Email ID" name="email" rules={[
                                                    {
                                                        type: 'email',
                                                        message: 'Please input valid Email ID'
                                                    },
                                                    {
                                                        required: true,
                                                        message: 'Please input your E-mail!',
                                                    },
                                                ]}>
                                                    <Input placeholder='email@domain.in' />
                                                </Form.Item>
                                                <Form.Item label="Password" name='password' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Password Field is Mandatory'
                                                    },
                                                ]}>
                                                    <Input.Password placeholder="Password" />
                                                </Form.Item>
                                                <Form.Item name='captcha-view'>
                                                    <LoadCanvasTemplate />
                                                </Form.Item>
                                                <Form.Item label="Captcha" name='captcha' rules={[
                                                    {
                                                        required: true,
                                                        message: 'Captcha is Mandatory'
                                                    },
                                                ]}>
                                                    <Input placeholder='captcha' />
                                                </Form.Item>
                                                <Form.Item
                                                    wrapperCol={{
                                                        offset: 8,
                                                        span: 16,
                                                    }}>
                                                    <Space>
                                                        <Button type="primary" htmlType="submit">
                                                            Login
                                                        </Button>
                                                        <Button htmlType="button">
                                                            Reset
                                                        </Button>
                                                    </Space>
                                                </Form.Item>
                                            </Form>

                                        </Card>
                                    </Col>
                                </Row>
                            </div>




                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default LoginView;