import { DistrictsData } from '../Consts/DataConstants';
import { auth } from '../config/config';
export const processSheetData = (data) => {
    const processData = [];
    for (let i = 0; i < data.length; i++) {
        let tmp = {};
        tmp.key = i;
        tmp.uid = data[i].uid
        tmp.name = data[i].name
        tmp.fname = data[i].fname
        tmp.bg = data[i].bg
        tmp.district = data[i].district[0] + " - " + data[i].district[1]
        tmp.dob = data[i].dob
        tmp.add1 = data[i].add1
        tmp.add2 = data[i].add2
        tmp.add3 = data[i].add3
        tmp.pin = data[i].pin
        tmp.mno = data[i].mno
        tmp.pic = data[i].pic
        tmp.status = data[i].status
        tmp.id = data[i].id
        tmp.alteredTimestamp = data[i].alteredTimestamp
        tmp.timestamp = data[i].timestamp
        processData.push(tmp);
    }
    return processData;
}

export const districtCodeToDistrict = () => {
    const retMap = {}
    DistrictsData.forEach((dObj) => {
        dObj.children.forEach((v)=> {
            retMap[v['code']] = {"dist":dObj['value'], "sub" : v['value']}
        })
        retMap[dObj['children'][0]['code'].substring(0,3)] = dObj['value']
    })
    return retMap;
}

export const isValidAdditional = () => {
    return (auth && auth.currentUser && auth.currentUser.email && (auth.currentUser.email === "aetu@aetu.in"));
}
export const emailList = ['aetu@aetu.in', "user1a@aetu.in", "user2b@aetu.in", "user3c@aetu.in", "user4d@aetu.in", "user5e@aetu.in"]