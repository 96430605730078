import React, { useState, useCallback, useEffect } from 'react';
import 'antd/dist/antd.min.css'
import '../App.css';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    IdcardOutlined,
    PullRequestOutlined,
    PlusOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { LOCAL_LOCATION, LOGIN_VIEW } from '../Consts/Consts';
import { auth } from '../config/config';
import { isValidAdditional } from '../Utils/Utils'



const { Header, Sider, Content } = Layout;


const LayoutView = () => {
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();
    const viewCardClick = useCallback(() => navigate('/in/view', { replace: true }), [navigate]);
    const newCardClick = useCallback(() => navigate('/in/new', { replace: true }), [navigate]);
    const requestUpdateClick = useCallback(() => navigate('/in/req', { replace: true }), [navigate]);
    const [itemsList, setItemsList] = useState([
        {
            key: '1',
            icon: <IdcardOutlined />,
            label: 'Card View',
            onClick: viewCardClick
        },
        {
            key: '2',
            icon: <PlusOutlined />,
            label: 'New Card',
            onClick: newCardClick
        }
    ]);

    useEffect(() => {
        if (!window.localStorage.getItem(LOCAL_LOCATION)) {
            navigate(LOGIN_VIEW)
        }
        if (isValidAdditional()) {
            setItemsList([
                {
                    key: '1',
                    icon: <IdcardOutlined />,
                    label: 'Card View',
                    onClick: viewCardClick
                },
                {
                    key: '2',
                    icon: <PlusOutlined />,
                    label: 'New Card',
                    onClick: newCardClick
                }
                ,
                {
                    key: '3',
                    icon: <PullRequestOutlined />,
                    label: 'Pending Requests',
                    onClick: requestUpdateClick
                }
            ])
        } else {
            setItemsList([
                {
                    key: '1',
                    icon: <IdcardOutlined />,
                    label: 'Card View',
                    onClick: viewCardClick
                },
                {
                    key: '2',
                    icon: <PlusOutlined />,
                    label: 'New Card',
                    onClick: newCardClick
                }
            ])
        }
    }, [])
    const logout = () => {
        auth.signOut().then(() => {
            window.localStorage.removeItem(LOCAL_LOCATION);
            navigate(LOGIN_VIEW)
        }).catch((err) => {
            window.localStorage.removeItem(LOCAL_LOCATION);
            navigate(LOGIN_VIEW)
        })
    }
    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="logo" >AETU</div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={itemsList}
                />
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }}>
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => setCollapsed(!collapsed),
                    })}
                    <Button type="secondary" shape="round" icon={<LogoutOutlined />} onClick={logout}>LogOut</Button>
                </Header>
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: '88.8vh',
                        overflow: 'auto'
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>

    )
}

export default LayoutView