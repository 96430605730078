export const BloodGroupData = [
    { value: "A +ve" },
    { value: "A -ve" },
    { value: "B +ve" },
    { value: "B -ve" },
    { value: "AB +ve" },
    { value: "AB -ve" },
    { value: "O +ve" },
    { value: "O -ve" },
    { value: "Other" },
    { value: "Don't Know" }
];

export const DistrictsData = [
    {
        "value": "Anakapalli",
        "label": "Anakapalli",
        "children": [
            {
                "value": "Anakapalli",
                "label": "Anakapalli",
                "code": "AKP - AKP"
            },
            {
                "value": "Narasipatnam",
                "label": "Narasipatnam",
                "code": "AKP - NPM"
            }
        ]
    },
    {
        "value": "Ambedkar Konaseema",
        "label": "Ambedkar Konaseema",
        "children": [
            {
                "value": "Ambedkar Konaseema",
                "label": "Ambedkar Konaseema",
                "code": "AKS - AKS"
            },
            {
                "value": "Amalapuram",
                "label": "Amalapuram",
                "code": "AKS - AMP"
            },
            {
                "value": "Kothapeta",
                "label": "Kothapeta",
                "code": "AKS - KPT"
            },
            {
                "value": "Mandapeta",
                "label": "Mandapeta",
                "code": "AKS - MDP"
            },
            {
                "value": "Mummidivaram",
                "label": "Mummidivaram",
                "code": "AKS - MMD"
            },
            {
                "value": "Ramachandrapuram",
                "label": "Ramachandrapuram",
                "code": "AKS - RCM"
            },
            {
                "value": "Tatipaka",
                "label": "Tatipaka",
                "code": "AKS - TPK"
            }
        ]
    },
    {
        "value": "Annamaya",
        "label": "Annamaya",
        "children": [
            {
                "value": "Madhanapalli",
                "label": "Madhanapalli",
                "code": "ANM - MDP"
            },
            {
                "value": "Koduru",
                "label": "Koduru",
                "code": "ANM - KDR"
            },
            {
                "value": "Rayachoti",
                "label": "Rayachoti",
                "code": "ANM - RCT"
            },
            {
                "value": "Rajampeta",
                "label": "Rajampeta",
                "code": "ANM - RJP"
            }
        ]
    },
    {
        "value": "Alluri Sitharama Raju",
        "label": "Alluri Sitharama Raju",
        "children": [
            {
                "value": "Alluri Sitharama Raju",
                "label": "Alluri Sitharama Raju",
                "code": "ASR - ASR"
            }
        ]
    },
    {
        "value": "Anathapuram",
        "label": "Anathapuram",
        "children": [
            {
                "value": "Anathapuram",
                "label": "Anathapuram",
                "code": "ATP - ATP"
            },
            {
                "value": "Guntakal",
                "label": "Guntakal",
                "code": "ATP - GTL"
            },
            {
                "value": "Guthi",
                "label": "Guthi",
                "code": "ATP - GTY"
            },
            {
                "value": "Kalyanadurgam",
                "label": "Kalyanadurgam",
                "code": "ATP - KLD"
            },
            {
                "value": "Narpala",
                "label": "Narpala",
                "code": "ATP - NRL"
            },
            {
                "value": "Rayadurgam",
                "label": "Rayadurgam",
                "code": "ATP - RGD"
            },
            {
                "value": "Tadipatri",
                "label": "Tadipatri",
                "code": "ATP - TDP"
            },
            {
                "value": "Uravakonda",
                "label": "Uravakonda",
                "code": "ATP - UKD"
            }
        ]
    },
    {
        "value": "Palnadu",
        "label": "Palnadu",
        "children": [
            {
                "value": "Dachepalligurajala",
                "label": "Dachepalligurajala",
                "code": "PLD - DLC"
            },
            {
                "value": "Macherla",
                "label": "Macherla",
                "code": "PLD - MCL"
            },
            {
                "value": "Narasaraopeta",
                "label": "Narasaraopeta",
                "code": "PLD - NRT"
            },
            {
                "value": "Piduguralla",
                "label": "Piduguralla",
                "code": "PLD - PDL"
            },
            {
                "value": "Sattenapali",
                "label": "Sattenapali",
                "code": "PLD - STP"
            },
            {
                "value": "Vinukonda",
                "label": "Vinukonda",
                "code": "PLD - VNK"
            }
        ]
    },
    {
        "value": "Bapatla",
        "label": "Bapatla",
        "children": [
            {
                "value": "Bapatla",
                "label": "Bapatla",
                "code": "BPT - BPT"
            },{
                "value": "Adhanki",
                "label": "Adhanki",
                "code": "BPT - ADK"
            },
            {
                "value": "Chirala",
                "label": "Chirala",
                "code": "BPT - CRL"
            },
            {
                "value": "Repalli",
                "label": "Repalli",
                "code": "BPT - RPL"
            },
            {
                "value": "Vemuru",
                "label": "Vemuru",
                "code": "BPT - VMR"
            }
        ]
    },
    {
        "value": "Chittoor",
        "label": "Chittoor",
        "children": [
            {
                "value": "Chittoor",
                "label": "Chittoor",
                "code": "CTR - CTR"
            },
            {
                "value": "Palamaneru",
                "label": "Palamaneru",
                "code": "CTR - PPK"
            }
        ]
    },
    {
        "value": "Eluru",
        "label": "Eluru",
        "children": [
            {
                "value": "Chinthalapudi",
                "label": "Chinthalapudi",
                "code": "ELR - CPD"
            },
            {
                "value": "Eluru",
                "label": "Eluru",
                "code": "ELR - ELR"
            },
            {
                "value": "Jangareedygudem",
                "label": "Jangareedygudem",
                "code": "ELR - JRG"
            },
            {
                "value": "Nujividu",
                "label": "Nujividu",
                "code": "ELR - NZD"
            }
        ]
    },
    {
        "value": "East Godavari",
        "label": "East Godavari",
        "children": [
            {
                "value": "East Godavari",
                "label": "East Godavari",
                "code": "ESD - ESD"
            },
            {
                "value": "Nidadhavolu",
                "label": "Nidadhavolu",
                "code": "ESD - NDD"
            },
            {
                "value": "Rajahmundry",
                "label": "Rajahmundry",
                "code": "ESD - RJY"
            }
        ]
    },
    {
        "value": "Guntur",
        "label": "Guntur",
        "children": [
            {
                "value": "Guntur",
                "label": "Guntur",
                "code": "GNT - GNT"
            },
            {
                "value": "Mangalagiri",
                "label": "Mangalagiri",
                "code": "GNT - MNG"
            },
            {
                "value": "Ponnuru",
                "label": "Ponnuru",
                "code": "GNT - PNR"
            },
            {
                "value": "Tenali",
                "label": "Tenali",
                "code": "GNT - TNL"
            }
        ]
    },
    {
        "value": "Kakinada",
        "label": "Kakinada",
        "children": [
            {
                "value": "Jaggampeta",
                "label": "Jaggampeta",
                "code": "KKD - JPT"
            },
            {
                "value": "Kakinada",
                "label": "Kakinada",
                "code": "KKD - KKD"
            },
            {
                "value": "Pithapuram",
                "label": "Pithapuram",
                "code": "KKD - PTP"
            },
            {
                "value": "Samarlakota",
                "label": "Samarlakota",
                "code": "KKD - SLO"
            },
            {
                "value": "Tuni",
                "label": "Tuni",
                "code": "KKD - TNI"
            }
        ]
    },
    {
        "value": "Kurnool",
        "label": "Kurnool",
        "children": [
            {
                "value": "Aluru",
                "label": "Aluru",
                "code": "KNL - ALR"
            },
            {
                "value": "Adoni",
                "label": "Adoni",
                "code": "KNL - AND"
            },
            {
                "value": "Kodumur",
                "label": "Kodumur",
                "code": "KNL - KMR"
            },
            {
                "value": "Kurnool",
                "label": "Kurnool",
                "code": "KNL - KNL"
            },
            {
                "value": "Pattikonda",
                "label": "Pattikonda",
                "code": "KNL - PKD"
            },
            {
                "value": "Yammiganur",
                "label": "Yammiganur",
                "code": "KNL - YNR"
            }
        ]
    },
    {
        "value": "Krishna",
        "label": "Krishna",
        "children": [
            {
                "value": "Gudivada",
                "label": "Gudivada",
                "code": "KRD - GDV"
            },
            {
                "value": "Machilipatanam",
                "label": "Machilipatanam",
                "code": "KRD - MTM"
            }
        ]
    },
    {
        "value": "Manyam",
        "label": "Manyam",
        "children": [
            {
                "value": "Parvathipuram",
                "label": "Parvathipuram",
                "code": "MYM - PVP"
            },
            {
                "value": "Salur",
                "label": "Salur",
                "code": "MYM - SLR"
            },
            {
                "value": "Palakonda",
                "label": "Palakonda",
                "code": "MYM - PKD"
            }
        ]
    },
    {
        "value": "Nandyala",
        "label": "Nandyala",
        "children": [
            {
                "value": "Allagadda",
                "label": "Allagadda",
                "code": "NDL - ALG"
            },
            {
                "value": "Atmakuru",
                "label": "Atmakuru",
                "code": "NDL - ATR"
            },
            {
                "value": "Bethamcharala",
                "label": "Bethamcharala",
                "code": "NDL - BCL"
            },
            {
                "value": "Banaganapalli",
                "label": "Banaganapalli",
                "code": "NDL - BPL"
            },
            {
                "value": "Dhone",
                "label": "Dhone",
                "code": "NDL - DNC"
            },
            {
                "value": "Koilakuntla",
                "label": "Koilakuntla",
                "code": "NDL - KKL"
            },
            {
                "value": "Nandyala",
                "label": "Nandyala",
                "code": "NDL - NDL"
            },
            {
                "value": "Nandikotkur",
                "label": "Nandikotkur",
                "code": "NDL - NDK"
            }
        ]
    },
    {
        "value": "Nellore",
        "label": "Nellore",
        "children": [
            {
                "value": "Kaluvai",
                "label": "Kaluvai",
                "code": "NLR - KLY"
            },
            {
                "value": "Alluru",
                "label": "Alluru",
                "code": "NLR - ALR"
            },
            {
                "value": "Atmakur",
                "label": "Atmakur",
                "code": "NLR - ATM"
            },
            {
                "value": "Ananthasaagaram",
                "label": "Ananthasaagaram",
                "code": "NLR - ATS"
            },
            {
                "value": "Buchi",
                "label": "Buchi",
                "code": "NLR - BCH"
            },
            {
                "value": "Bitragunta",
                "label": "Bitragunta",
                "code": "NLR - BTR"
            },
            {
                "value": "Gudur",
                "label": "Gudur",
                "code": "NLR - GUD"
            },
            {
                "value": "Kaligiri",
                "label": "Kaligiri",
                "code": "NLR - KLG"
            },
            {
                "value": "Kavali",
                "label": "Kavali",
                "code": "NLR - KLV"
            },
            {
                "value": "Muthukur",
                "label": "Muthukur",
                "code": "NLR - MTK"
            },
            {
                "value": "Nellore",
                "label": "Nellore",
                "code": "NLR - NLR"
            },
            {
                "value": "Podalakur",
                "label": "Podalakur",
                "code": "NLR - PDK"
            },
            {
                "value": "Pamuru",
                "label": "Pamuru",
                "code": "NLR - PMR"
            },
            {
                "value": "Rapuru",
                "label": "Rapuru",
                "code": "NLR - RPU"
            },
            {
                "value": "Udayagiri",
                "label": "Udayagiri",
                "code": "NLR - UDG"
            },
            {
                "value": "Vidavaluru",
                "label": "Vidavaluru",
                "code": "NLR - VDR"
            },
            {
                "value": "Vaakadu",
                "label": "Vaakadu",
                "code": "NLR - VKD"
            },
            {
                "value": "Vinjamuru",
                "label": "Vinjamuru",
                "code": "NLR - VMR"
            },
            {
                "value": "Venkatachalam",
                "label": "Venkatachalam",
                "code": "NLR - VTC"
            }
        ]
    },
    {
        "value": "NTR",
        "label": "NTR",
        "children": [
            {
                "value": "Jaggaiahpeta",
                "label": "Jaggaiahpeta",
                "code": "NTR - JPT"
            },
            {
                "value": "Nandigama",
                "label": "Nandigama",
                "code": "NTR - NDG"
            },
            {
                "value": "Tiruvuru",
                "label": "Tiruvuru",
                "code": "NTR - TVR"
            },
            {
                "value": "Vijayawada",
                "label": "Vijayawada",
                "code": "NTR - VJA"
            }
        ]
    },
    {
        "value": "Prakasam",
        "label": "Prakasam",
        "children": [
            {
                "value": "Markapuram",
                "label": "Markapuram",
                "code": "PKM - MRK"
            },
            {
                "value": "Ongole",
                "label": "Ongole",
                "code": "PKM - ONG"
            },
            {
                "value": "Podili",
                "label": "Podili",
                "code": "PKM - PDL"
            },
            {
                "value": "Prakasam",
                "label": "Prakasam",
                "code": "PKM - PKM"
            }
        ]
    },
    {
        "value": "Srikakulam",
        "label": "Srikakulam",
        "children": [
            {
                "value": "Srikakulam",
                "label": "Srikakulam",
                "code": "SKL - SKL"
            },
            {
                "value": "Narsapeta",
                "label": "Narsapeta",
                "code": "SLK - NSP"
            }
        ]
    },
    {
        "value": "Sri Sathya Sai",
        "label": "Sri Sathya Sai",
        "children": [
            {
                "value": "Dharmavaram",
                "label": "Dharmavaram",
                "code": "SSS - DMM"
            },
            {
                "value": "Gorantla",
                "label": "Gorantla",
                "code": "SSS - GTL"
            },
            {
                "value": "Hindupuram",
                "label": "Hindupuram",
                "code": "SSS - HDP"
            },
            {
                "value": "Kadiri",
                "label": "Kadiri",
                "code": "SSS - KDR"
            },
            {
                "value": "Madakasira",
                "label": "Madakasira",
                "code": "SSS - MDS"
            },
            {
                "value": "Penukonda",
                "label": "Penukonda",
                "code": "SSS - PKD"
            },
            {
                "value": "Puttaparthi",
                "label": "Puttaparthi",
                "code": "SSS - PTP"
            }
        ]
    },
    {
        "value": "Tirupati",
        "label": "Tirupati",
        "children": [
            {
                "value": "Puttur",
                "label": "Puttur",
                "code": "TPT - PTR"
            },
            {
                "value": "Kalahasthi",
                "label": "Kalahasthi",
                "code": "TPT - SKT"
            },
            {
                "value": "Tirupati",
                "label": "Tirupati",
                "code": "TPT - TPT"
            }
        ]
    },
    {
        "value": "Visakapatnam",
        "label": "Visakapatnam",
        "children": [
            {
                "value": "Gajuwaka",
                "label": "Gajuwaka",
                "code": "VSP - GWK"
            },
            {
                "value": "Visakapatnam",
                "label": "Visakapatnam",
                "code": "VSP - VSP"
            }
        ]
    },
    {
        "value": "Vizayanagaram",
        "label": "Vizayanagaram",
        "children": [
            {
                "value": "Bobbili",
                "label": "Bobbili",
                "code": "VZM - BBL"
            },
            {
                "value": "Cheepurapali",
                "label": "Cheepurapali",
                "code": "VZM - CGM"
            },
            {
                "value": "Gajapathinagaram",
                "label": "Gajapathinagaram",
                "code": "VZM - GPM"
            },
            {
                "value": "Nelimarla",
                "label": "Nelimarla",
                "code": "VZM - NML"
            },
            {
                "value": "Pusapatirega",
                "label": "Pusapatirega",
                "code": "VZM - PPR"
            },
            {
                "value": "S.Kota",
                "label": "S.Kota",
                "code": "VZM - SKT"
            },
            {
                "value": "Vizayanagaram",
                "label": "Vizayanagaram",
                "code": "VZM - VZM"
            },
            {
                "value": "Rajam",
                "label": "Rajam",
                "code": "VZM - RJM"
            }
        ]
    },
    {
        "value": "West Godavari",
        "label": "West Godavari",
        "children": [
            {
                "value": "Akividu",
                "label": "Akividu",
                "code": "WGD - AKV"
            },
            {
                "value": "Bhimavaram",
                "label": "Bhimavaram",
                "code": "WGD - BVM"
            },
            {
                "value": "Narasaapuram",
                "label": "Narasaapuram",
                "code": "WGD - NSP"
            },
            {
                "value": "Palakollu",
                "label": "Palakollu",
                "code": "WGD - PLK"
            },
            {
                "value": "Tanuku",
                "label": "Tanuku",
                "code": "WGD - TNK"
            },
            {
                "value": "Tadepalligudem",
                "label": "Tadepalligudem",
                "code": "WGD - TPG"
            },
            {
                "value": "West Godavari",
                "label": "West Godavari",
                "code": "WGD - WGD"
            }
        ]
    },
    {
        "value": "YSR",
        "label": "YSR",
        "children": [
            {
                "value": "Badvelu",
                "label": "Badvelu",
                "code": "YSR - BDL"
            },
            {
                "value": "Jammalamadugu",
                "label": "Jammalamadugu",
                "code": "YSR - JMD"
            },
            {
                "value": "Kadapa",
                "label": "Kadapa",
                "code": "YSR - KDP"
            },
            {
                "value": "Kamalapuram",
                "label": "Kamalapuram",
                "code": "YSR - KMP"
            },
            {
                "value": "Mydukur",
                "label": "Mydukur",
                "code": "YSR - MDK"
            },
            {
                "value": "Prodduturu",
                "label": "Prodduturu",
                "code": "YSR - PDT"
            },
            {
                "value": "Pulivendulu",
                "label": "Pulivendulu",
                "code": "YSR - PVD"
            }
        ]
    }
]