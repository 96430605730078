import React, { useEffect, useState } from 'react'
import { loadImage } from '../Actions/Actions'
import BackCard from './BackCard'
import FrontCard from './FrontCard'
import { LoadingOutlined } from '@ant-design/icons';
import { fetchImageByUid } from '../Actions/Main-Actions';
import { message } from 'antd';

export const FullCard = (props) => {
    const data = props.data;
    const [imageData, setImageData] = useState();
    const fetchImage = async () => {
        try{
            const imgBlob = await fetchImageByUid(data.id);
            setImageData(imgBlob);
        }catch(error){
            message.error(error);
        }
    }
    useEffect(() => {
        setImageData(undefined)
        fetchImage();
    }, [props])


    return (
        <>
            {(!data || !imageData) && <div className='align-center'><LoadingOutlined /></div>}
            {data && imageData && <div className='align-center'>
                <svg id="fullcard" xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="245"
                    height="400"
                    fill="none"
                    viewBox="0 0 245 400"
                >
                    <FrontCard data={data} pic={imageData}/>
                    <BackCard data={data} />
                </svg>
            </div>}

        </>
    )
}
