import { db } from "../config/config";
import { collection, query, getDocs, getDoc, where, doc, updateDoc, runTransaction } from "firebase/firestore";
import { getBlob, getStorage, ref, uploadString } from "firebase/storage";
export const typeDB = {
    "DB": 'AETU-MAIN-DB',
    "PIC": 'AETU-MAIN-PIC',
    "DELETE-DB": 'DELETE-AETU-MAIN',
    "EDIT-DB": 'EDIT-AETU-MAIN'
}

export const loadAllEditRequests = async () => {
    const messagesRef = collection(db, typeDB["EDIT-DB"])
    const q = query(messagesRef)
    return getDocs(q).then(docsSnap => {
        const data = []
        docsSnap.forEach(doc => {
            data.push(doc.data());
        })
        return data;
    }).catch(error => {
        console.error("Error - ", error);
        throw new Error("Unable to Load Data");
    })
}

export const loadAllDeleteRequests = async () => {
    const messagesRef = collection(db, typeDB["DELETE-DB"])
    const q = query(messagesRef)
    return getDocs(q).then(docsSnap => {
        const data = []
        docsSnap.forEach(doc => {
            data.push(doc.data());
        })
        return data;
    }).catch(error => {
        console.error("Error - ", error);
        throw new Error("Unable to Load Data");
    })
}

export const approveDeleteRequest = async (data) => {
    try {
        await runTransaction(db, async (transaction) => {
            const mainDocRef = doc(collection(db, typeDB["DB"]), data.id);
            const mainDoc = await transaction.get(mainDocRef);
            if (!mainDoc.exists()) {
                throw "Card doesn't exists !";
            }
            const deleteDocRef = doc(collection(db, typeDB["DELETE-DB"]), data.id);
            transaction.update(mainDocRef, { status: data['status'], alteredTimestamp: Date.now() });
            transaction.delete(deleteDocRef)
        });
        console.info("Transaction successfully committed!");
        return data;
    } catch (e) {
        console.error("Transaction failed: ", e);
        return { error: e }
    }
}

export const revokeDeleteRequest = async (data) => {
    try {
        await runTransaction(db, async (transaction) => {
            const mainDocRef = doc(collection(db, typeDB["DB"]), data.id);
            const mainDoc = await transaction.get(mainDocRef);
            if (!mainDoc.exists()) {
                throw "Card doesn't exists !";
            }
            const deleteDocRef = doc(collection(db, typeDB["DELETE-DB"]), data.id);
            transaction.update(mainDocRef, { status: 'Active', alteredTimestamp: Date.now() });
            transaction.delete(deleteDocRef)
        });
        console.info("Transaction successfully committed!");
        return data;
    } catch (e) {
        console.error("Transaction failed: ", e);
        return { error: e }
    }
}

export const approveEditRequest = async (data) => {
    try {
        await runTransaction(db, async (transaction) => {
            const mainDocRef = doc(collection(db, typeDB["DB"]), data.id);
            const picDocRef = doc(collection(db, typeDB["PIC"]), data.id);
            const editDocRef = doc(collection(db, typeDB["EDIT-DB"]), data.id);
            const mainDoc = await transaction.get(mainDocRef);
            if (!mainDoc.exists()) {
                throw "Card doesn't exists !";
            }
            const picData = data['pic']
            const newData = { ...data, status: "Active", alteredTimestamp: Date.now() }
            delete newData['pic']

            if (data.mno && data.id !== data.mno) {
                const newMainDocRef = doc(collection(db, typeDB["DB"]), data.mno);
                const newPicDocRef = doc(collection(db, typeDB["PIC"]), data.mno);
                const newMainDoc = await transaction.get(newMainDocRef);
                const newPicDoc = await transaction.get(newPicDocRef);
                const picDoc = await transaction.get(picDocRef);
                if (newMainDoc.exists() || newPicDoc.exists()) {
                    throw "Already Card Exists with Same Mobile No !!";
                }

                const d = { ...mainDoc.data(), ...newData }
                d['id'] = data.mno
                let pd = { ...picDoc.data(), rid: data.mno }
                if (picData)
                    pd = { ...pd, pic: picData }
                transaction.set(newMainDocRef, d)
                transaction.set(newPicDocRef, pd)
                transaction.delete(mainDocRef)
                transaction.delete(picDocRef)

            } else {
                transaction.update(mainDocRef, newData);
                if (picData) {
                    transaction.update(picDocRef, { pic: picData })
                }
            }
            // Edit Doc Delete 
            transaction.delete(editDocRef)
        });
        console.info("Transaction successfully committed!");
        return data;
    } catch (e) {
        console.error("Transaction failed: ", e);
        return { error: e }
    }
}

export const revokeEditRequest = async (data) => {
    try {
        await runTransaction(db, async (transaction) => {
            const mainDocRef = doc(collection(db, typeDB["DB"]), data.id);
            const mainDoc = await transaction.get(mainDocRef);
            if (!mainDoc.exists()) {
                throw "Card doesn't exists !";
            }
            const editDocRef = doc(collection(db, typeDB["EDIT-DB"]), data.id);
            transaction.update(mainDocRef, { status: 'Active', alteredTimestamp: Date.now() });
            transaction.delete(editDocRef)
        });
        console.info("Transaction successfully committed!");
        return data;
    } catch (e) {
        console.error("Transaction failed: ", e);
        return { error: e }
    }
}

export const editRequest = async (data) => {
    try {
        await runTransaction(db, async (transaction) => {
            const mainDocRef = doc(collection(db, typeDB["DB"]), data.id);
            const mainDoc = await transaction.get(mainDocRef);
            if (!mainDoc.exists()) {
                throw "Unable to fetch the Card !";
            }
            const deleteDbRef = doc(collection(db, typeDB["EDIT-DB"]), data.id);
            transaction.set(deleteDbRef, data);
            const obj = "Edit Requested"
            transaction.update(mainDocRef, { status: obj });
        });
        data = { ...data, status: "Edit Requested" };
        console.info("Transaction successfully committed!");
        return data;
    } catch (e) {
        console.error("Transaction failed: ", e);
        return { error: e }
    }
}

export const createNewData = async (data) => {
    try {
        await runTransaction(db, async (transaction) => {
            const recData = { ...data, pic: "" };
            const picData = {
                rid: data['mno'],
                pic: data['pic']
            }

            const mainDocRef = doc(collection(db, typeDB["DB"]), data.mno);
            const mainDoc = await transaction.get(mainDocRef);
            if (mainDoc.exists()) {
                throw "Card already exists with that Mobile No";
            }
            const picDocRef = doc(collection(db, typeDB["PIC"]), data.mno);
            transaction.set(mainDocRef, recData);
            transaction.set(picDocRef, picData)
        });
        data = { ...data, status: "Delete Requested - " + data['status'] };
        console.info("Transaction successfully committed!");
        return data;
    } catch (e) {
        console.error("Transaction failed: ", e);
        return { error: e }
    }
}

export const deleteRequest = async (data) => {
    try {
        await runTransaction(db, async (transaction) => {
            const mainDocRef = doc(collection(db, typeDB["DB"]), data.id);
            const mainDoc = await transaction.get(mainDocRef);
            if (!mainDoc.exists()) {
                throw "Document doesn't exists";
            }
            const deleteDbRef = doc(collection(db, typeDB["DELETE-DB"]), data.id);
            transaction.set(deleteDbRef, data);
            const obj = "Delete Requested - " + data['status']
            transaction.update(mainDocRef, { status: obj });
        });
        data = { ...data, status: "Delete Requested - " + data['status'] };
        console.info("Transaction successfully committed!");
        return data;
    } catch (e) {
        console.error("Transaction failed: ", e);
        return undefined
    }
}

export const loadDataByDistrict = (type, districtCode) => {
    const messagesRef = collection(db, typeDB[type])
    const q = query(messagesRef, where("dc", "==", districtCode))
    return getDocs(q).then(docsSnap => {
        const data = [];
        docsSnap.forEach(doc => {
            const tmp = doc.data();
            tmp['id'] = doc.id
            data.push(tmp);
        })
        return data;
    }).catch(error => {
        console.error("Error - ", error);
        throw new Error("Unable to Load Data");
    })
}

export const loadData = (type) => {
    const messagesRef = collection(db, typeDB[type])
    const q = query(messagesRef)
    return getDocs(q).then(docsSnap => {
        const data = [];
        docsSnap.forEach(doc => {
            const tmp = doc.data();
            tmp['id'] = doc.id
            data.push(tmp);
        })
        return data;
    }).catch(error => {
        console.error("Error - ", error);
        throw new Error("Unable to Load Data");
    })
}

export const fetchImageByUid = (id) => {
    const docRef = doc(collection(db, typeDB['PIC']), id)
    return getDoc(docRef).then(doc => {
        return doc.data().pic;
    }).catch(error => {
        console.error("Error - ", error);
        throw new Error("Unable to Load Data");
    })
}

const getIdDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = (date.getFullYear() % 100).toString();
    const month = (date.getMonth() + 1).toString();
    const mOfSet = month.length === 1 ? "0" + month : month;
    return year + mOfSet;
}

const timestampSorter = (myList) => {
    myList.sort((x, y) => {
        return x.timestamp - y.timestamp;
    })
    return myList;
}

export const generateId = async () => {
    const updatedData = []
    const error = []
    const data = await loadData("DB")
    const nurd = []
    let drcm = new Map();
    data.forEach((r) => {
        let v = drcm.get(r['dc'])
        if (v === undefined) {
            drcm.set(r['dc'], []);
        }
        drcm.get(r['dc']).push(r);
    })

    drcm.forEach((v, _) => {
        let allVals = timestampSorter(v)
        allVals.forEach((d, i) => {
            if (d['uid'] || (d['uid'] && d['uid'] !== "")) {
                return;
            }
            let idm = getIdDate(d['timestamp']);
            const currentUid = i + 1;
            let currStrUid = currentUid + "";
            let count = currStrUid.length;
            while (count++ < 4) {
                currStrUid = "0" + currStrUid;
            }
            d['uid'] = d['dc'] + " " + idm + " " + currStrUid;
            nurd.push(d);

        })
    })
    nurd.forEach((r) => {
        updateDoc(doc(db, typeDB['DB'], r['id']), { uid: r['uid'] }).then((res) => {
            updatedData.push(res)
        }).catch(err => {
            error.push(err)
        })
    })
    updateRegisteredMemberCount(data)
    return {updatedData : updatedData, error : error}
}

const updateRegisteredMemberCount = (data) => {
    let drcm = {}
    data.forEach((r) => {
            let v = drcm[r['dc']]
            if (v === undefined) {
                drcm[r['dc']] = 0;
            }
                drcm[r['dc']] = drcm[r['dc']] + 1;
        })
    const storage = getStorage();
    const storageRef = ref(storage, 'RegisteredMemberCount.txt');
    uploadString(storageRef, JSON.stringify(drcm)).then((res)=>{
        console.info("Uploaded data")
    }).catch((err)=>{
        console.error(err)
    });
}

export const fetchRegisteredMemberCount = () => {
    const storage = getStorage();
    const storageRef = ref(storage, 'RegisteredMemberCount.txt');
    return getBlob(storageRef).then((res)=>{
        return res.text().then((r)=> {
            return {"data" : JSON.parse(r)};
        })
    }).catch((err)=> {
        console.error(err)
        return {"error" : err}
    })
}
// export const freshRefreshData =  () => {
//     // console.log(rd);
//     // const pm = new Map()
//     // pd.forEach(picD => {
//     //     pm.set(picD['rid'], picD)
//     // })

//     // try {
//     //     rd.forEach(async recData => {
//     //             await runTransaction(db, async (transaction) => {
//     //                 const picData = pm.get(recData['mno'])
//     //                 console.log(recData)
//     //                 console.log(pm.get(recData['mno']))
        
//     //                 const mainDocRef = doc(collection(db, typeDB["DB"]), recData['mno']);
//     //                 const mainDoc = await transaction.get(mainDocRef);
//     //                 if (mainDoc.exists()) {
//     //                     throw "Card already exists with that Mobile No";
//     //                 }
//     //                 const picDocRef = doc(collection(db, typeDB["PIC"]), recData['mno']);
//     //                 transaction.set(mainDocRef, recData);
//     //                 transaction.set(picDocRef, picData)
//     //             });
//     //     })
//     // }catch (e) {
//     //     console.log("Transaction failed: ", e);
//     //     return { error: e }
//     // }
//     //     console.log("Transaction successfully committed!");
// }