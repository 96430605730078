import React from 'react'

export default function MaintenanceView() {
    return (
        <>
            <section className="hero-bg">
                <div id="hero">
                    <div className="hero-container">
                        <div className="wow fadeIn">
                            <div className="site-card-wrapper">
                                <h1>Web Application is currently under Maintenance</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
