import { Row, Form, Col, Modal, Select, Button } from 'antd';
import { useEffect, useState } from 'react';
import { deleteRequest } from '../Actions/Main-Actions';
const DeleteView = ({ isOpen, closeView, record, updateData }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const options = [
        {
            value: 'Deceased/Passed'
        },
        {
            value: 'Cancel Membership'
        }
    ]
    useEffect(() => {
        form.resetFields();
        setLoading(false);
        setData(record)
    }, [record])
    return (
        <Modal
            open={isOpen}
            title="Initiate Delete Request"
            cancelText="Cancel"
            onCancel={closeView}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={closeView}>
                    Cancel
                </Button>,
                <Button key="submit" type="danger" loading={loading} onClick={() => {
                    setLoading(true);
                    form
                        .validateFields()
                        .then(async (values) => {
                            form.resetFields();
                            const obj = {
                                uid: record['uid'],
                                id: record['id'],
                                status: values['reason']
                            }
                            const dr = await deleteRequest(obj);
                            updateData(dr)
                            setLoading(false);
                            closeView()
                        })
                        .catch((info) => {
                            console.error('Validate Failed:', info);
                            setLoading(false);
                        });
                }}>
                    Request Delete
                </Button>,
            ]}
        >
            {data &&
                [
                    { 'key': 'uid', 'value': 'Card Id' },
                    { 'key': 'name', 'value': 'Name' },
                    { 'key': 'fname', 'value': 'Father Name' },
                    { 'key': 'mno', 'value': 'Mobile Number' },
                    { 'key': 'district', 'value': 'District' }
                ].map((key, index) => {
                    return <>
                        <Row>
                            <Col span={3}></Col>
                            <Col span={8}>
                                <p key={index}>
                                    <span style={{ fontWeight: 400 }}>{key['value']}:</span>
                                </p>
                            </Col>
                            <Col span={10}>
                                <span style={{ fontWeight: 300 }}>{data[key['key']]}</span>
                            </Col>
                        </Row>

                    </>
                }
                )
            }
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item style={{ fontWeight: 700 }} label="Reason" name="reason" rules={[
                    {
                        required: true,
                        type: 'string',
                        message: 'Invalid reason, Please select the reason for delete'
                    },
                ]}>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={options}
                    />
                </Form.Item>

            </Form>
        </Modal>

    );
};

export default DeleteView